<template lang='pug'>
  div
    slot Main EHR content selected by the router
</template>

<script>
export default {
  name: 'PrintLayout'
}
</script>

<style scoped>

</style>
