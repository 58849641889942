export const APP_ICONS = {
  activity: 'procedures',
  activityClose: 'hourglass-end',
  activityOpen: 'hourglass-start',
  barcode: 'barcode',
  classList: 'user-friends',
  configure: 'cog',
  copy: 'copy',
  currentCourse: 'folder-plus',
  consumer: 'hospital-alt',
  course: 'laptop-medical',
  download: 'download',
  edit: 'edit',
  ehrPage: 'book-medical',
  eraser: 'eraser',
  exitToLms: 'sign-out-alt',
  externalLink: 'external-link-alt',
  file: 'file-medical',
  filter: 'filter',
  forceSubmit: 'lock',
  itemDetails: 'cog',
  retractSubmit: 'unlock',
  list: 'list',
  lobj: 'pills',
  menu: 'bars',
  new: 'pen',
  save: 'check',
  signIn: 'sign-in-alt',
  signOut: 'sign-out-alt',
  seed: 'heartbeat',
  search: 'search',
  returnToSeedList: 'list',
  rawEhr: 'th-large',
  question: 'question',
  sortAsc: 'sort-down',
  sortDesc: 'sort-up',
  sortNone: 'sort',
  stopwatch: 'clock',
  stopCircle: 'stop-circle',
  student: 'user-nurse',
  table: 'table',
  tags: 'tag', // for filtering by tag applied to an object
  trash: 'trash-alt',
  undo: 'undo',
  upload: 'upload',
  view: 'eye',

}
