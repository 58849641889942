import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=45ee16de&scoped=true&lang=pug"
import script from "./AppFooter.vue?vue&type=script&lang=js"
export * from "./AppFooter.vue?vue&type=script&lang=js"
import style0 from "./AppFooter.vue?vue&type=style&index=0&id=45ee16de&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45ee16de",
  null
  
)

export default component.exports