<template lang="pug">
  div(id='nav-menu', :class='{ iconsOnly: iconsOnly }')
    router-link(
      :class="routeClass('/courses')",
      to="/courses", class='router-item level1')
      fas-icon(class="fa", :icon="appIcons.course")
      span(v-show='!iconsOnly') &nbsp; {{navText.COURSES_NAV_LABEL}}
    router-link(
      v-if="hasCourse",
      :class="routeClass('/course')",
      :to="{ name: 'course', query: { courseId: course._id }}",
      :title='ehrTextFn.GOTO_COURSE(courseTitle)',
      class='router-item level3')
      fas-icon(class="fa", :icon="appIcons.currentCourse")
      span(v-show='!iconsOnly') &nbsp; 当前课程
    router-link(
      :class="routeClass('/lms-instructor-activity')",
      v-if="hasCourse && hasActivity",
      to="/lms-instructor-activity",
      :title='ehrTextFn.GOTO_ACTIVITY(activityName)',
      class='router-item level3')
      fas-icon(class="fa", :icon="appIcons.activity")
      span(v-show='!iconsOnly') &nbsp; 当前活动
    router-link(
      :class="routeClass('/learning-objects')",
      to="/learning-objects", :title='navText.LOBJ_LABEL', class='router-item level1 lobj-theme')
      fas-icon(class="fa", :icon="appIcons.lobj")
      span(v-show='!iconsOnly') &nbsp; {{navText.LOBJ_LABEL}}
    router-link(
      :class="routeClass('/seed-list')",
      to="/seed-list", :title='navText.SEED_LIST_LABEL', class='router-item level1 seed-theme')
      fas-icon(class="fa", :icon="appIcons.seed")
      span(v-show='!iconsOnly') &nbsp; {{navText.SEED_LIST_LABEL}}
    router-link(
      :class="routeClass('/fileList')",
      to="/fileList", :title='navText.FILE_LABEL', class='router-item level1')
      fas-icon(class="fa", :icon="appIcons.file")
      span(v-show='!iconsOnly') &nbsp; {{navText.FILE_LABEL}}
    //div(class='mode-toggle')
      input(type="checkbox", id="creator", @input="setDevContent", :checked="isDevelopingContent",
        :title='navText.DESIGNER_MODE_LABEL')
      label(for='creator') &nbsp; {{navText.DESIGNER_MODE_LABEL}}
    //transition(name="fade")
      span(v-if="showEx", class="explain-text") &nbsp; {{navText.DESIGNER_MODE_EXPLAIN}}

    //router-link(
      :class="routeClass('/userSettings')",
      to="/user-settings", :title='navText.USER_SETTINGS', class='router-item level1')
      fas-icon(class="fa", :icon="appIcons.configure")
      span(v-show='!iconsOnly') &nbsp; {{navText.USER_SETTINGS}}

    router-link( v-if="isDevelopingContent",
      :class="routeClass('/consumer')",
      :to="{ name: 'consumer', query: { consumerId: consumerId } }",
      :title='navText.LMS_LABEL',
      class='router-item level1')
      fas-icon(class="fa", :icon="appIcons.consumer")
      span(v-show='!iconsOnly') &nbsp; {{navText.LMS_LABEL}}
    a(v-if="exitUrl", href="#", @click.prevent="exitToLms", :title='navText.EXIT_LABEL', class='router-item level1')
      fas-icon(class="fa", :icon="appIcons.exitToLms")
      span(v-show='!iconsOnly') &nbsp; {{navText.EXIT_LABEL}}
    hr
    div(class='mode-toggle')
      input(type="checkbox", id="creator", @input="setDevContent", :checked="isDevelopingContent",
        :title='navText.DESIGNER_MODE_LABEL')
      label(for='creator') &nbsp; {{navText.DESIGNER_MODE_LABEL}}
    transition(name="fade")
      span(v-if="showEx", class="explain-text") &nbsp; {{navText.DESIGNER_MODE_EXPLAIN}}
</template>

<script>
import { APP_ICONS } from '@/helpers/app-icons'
import StoreHelper from '../../helpers/store-helper'
import { t18EhrFunctions, t18EhrText } from '@/helpers/ehr-t18'
export default {
  components: {},
  props: {
    iconsOnly: { type: Boolean }
  },
  data: function () {
    return {
      appIcons: APP_ICONS
    }
  },
  computed: {
    navText () { return t18EhrText().zoneLmsNav},
    ehrTextFn () { return t18EhrFunctions()},
    activity () { return this.$store.getters['activityStore/activityRecord'] },
    activityName () { return this.activity.learningObjectName || this.activity.title },
    consumerId () { return StoreHelper.consumerId() },
    hasActivity () { return this.$store.getters['activityStore/hasActivity'] },
    hasCourse () { return this.$store.getters['courseStore/hasCourse'] },
    course () { return this.$store.getters['courseStore/course']},
    courseTitle () { return this.course ? this.course.title : undefined },
    isDevelopingContent () { return StoreHelper.isDevelopingContent() },
    isOutsideShowButtonLabels () { return StoreHelper.isOutsideShowButtonLabels() },
    exitUrl () { return StoreHelper.lmsUrl() },
    showEx () { return this.$store.getters['system/showExplanationTextOutside']},
    visitData () { return this.$store.getters['visit/visitData'] || {}}
  },
  methods: {
    exitToLms () {
      StoreHelper.exitToLms()
    },
    gotoActivity () {
      this.navigate('/lms-instructor-activity')
    },
    gotoCourse () {
      this.navigate({ name: 'course', query: { courseId: this.course._id } })
    },
    gotoCourses () {
      this.navigate('/courses')
    },
    gotoFiles () {
      this.navigate('/fileList')
    },
    gotoLObj () {
      this.navigate('/learning-objects')
    },
    gotoLms () {
      this.navigate({ name: 'consumer', query: { consumerId: StoreHelper.consumerId() } })
    },
    gotoSeeds () {
      this.navigate('/seed-list')
    },
    navigate (path) {
      // prevent Vue's NavigationDuplicated
      if (this.$route.path !== path) {
        this.$router.push(path)
      }
    },
    routeClass ( path) {
      return this.$route.path === path ? 'active-link' : ''
    },
    setOutsideLabels () {
      StoreHelper.setOutsideShowButtonLabels( !this.isOutsideShowButtonLabels)
    },
    setDevContent () {
      StoreHelper.setIsDevelopingContent( !this.isDevelopingContent)
    },
    truncate (text) {
      const lim = 18
      return text && text.length > lim ? `${text.substring(0, lim)}...` : text
    }
  }
}

</script>

<style lang="scss" scoped>
@import '../../scss/definitions';

/* 主题色 */
$primary-color: #051F54;
$secondary-color: #6c757d;
$background-color: #ffffff;
$text-color: #333333;
$border-color: #e0e0e0;

/* 导航菜单容器 */
#nav-menu {
  background-color: $background-color;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  max-width: 250px;
  position: relative;

  @media (max-width: 900px) {
    max-width: 100%;
  }
}

/* 导航链接 */
.router-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  text-decoration: none;
  color: $text-color;
  border-radius: 6px;
  transition: background-color 0.3s, color 0.3s;
  border-left: 3px solid transparent;

  &:hover {
    background-color: #E8F2FD;
    //border-left-color: $primary-color;
  }

  &.active-link {
    background-color: #E8F2FD;
    //border-left-color: $primary-color;
  }

  .fa {
    margin-right: 10px;
    color: $secondary-color;
  }

  span {
    flex: 1;
  }
}

/* 不同级别的菜单项缩进 */
/*.router-item.level1 {
  margin-left: 0;
}

.router-item.level2 {
  margin-left: 20px;
}

.router-item.level3 {
  margin-left: 40px;
}*/

/* 开发者模式切换 */
.mode-toggle {
  margin: 10px 0 10px 20px;
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    margin-right: 10px;
  }

  label {
    font-weight: bold;
    color: $text-color;
  }
}

/* 解释文本 */
.explain-text {
  font-size: 0.9em;
  color: $secondary-color;
  margin-left: 20px;
}

/* 分割线 */
hr {
  border: none;
  border-top: 1px solid $border-color;
  margin: 20px 0;
}

/* 响应式设计 */
@media (max-width: 768px) {
  #nav-menu {
    padding: 10px;
    margin: 10px;
  }

  .router-item {
    //flex-direction: column;
    //align-items: flex-start;
    padding: 10px 0;

    .fa {
      margin-right: 10px;
      margin-bottom: 0px;
    }

    span {
      flex: 1;
    }
  }

  /* 保持响应式缩进 */
  /*.router-item.level2 {
    margin-left: 10px;
  }

  .router-item.level3 {
    margin-left: 20px;
  }*/
}
</style>
