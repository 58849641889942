<template lang="pug">
  div(id='nav-menu', :class='{ iconsOnly: iconsOnly }')
    router-link(
      :class="routeClass('/courses')",
      to="/courses", class='router-item level1')
      fas-icon(class="fa", :icon="appIcons.course")
      span(v-show='!iconsOnly') &nbsp; {{navText.COURSES_NAV_LABEL}}
    router-link(
      v-if="hasCourse",
      :class="routeClass('/course')",
      :to="{ name: 'course', query: { courseId: course._id }}",
      :title='ehrTextFn.GOTO_COURSE(courseTitle)',
      class='router-item level3')
      fas-icon(class="fa", :icon="appIcons.currentCourse")
      span(v-show='!iconsOnly') &nbsp; 当前课程
    router-link(
      :class="routeClass('/lms-student-activity')",
      v-if="hasCourse && hasActivity",
      to="/lms-student-activity",
      :title='ehrTextFn.GOTO_ACTIVITY(activityName)',
      class='router-item level3'
      )
      fas-icon(class="fa", :icon="appIcons.activity")
      span(v-show='!iconsOnly') &nbsp; 当前活动
    a(href="#", @click.prevent="exitToLms", :title='navText.EXIT_LABEL', class='router-item level1')
      fas-icon(class="fa", :icon="appIcons.exitToLms")
      span(v-show='!iconsOnly') &nbsp; {{navText.EXIT_LABEL}}
</template>

<script>
import { APP_ICONS } from '@/helpers/app-icons'
import StoreHelper from '../../helpers/store-helper'
import UiLink from '@/app/ui/UiLink.vue'
import { t18EhrFunctions, t18EhrText } from '@/helpers/ehr-t18'
export default {
  components: { UiLink },
  props: {
    iconsOnly: { type: Boolean }
  },
  data: function () {
    return {
      appIcons: APP_ICONS
    }
  },
  computed: {
    navText () { return t18EhrText().zoneLmsNav},
    ehrTextFn () { return t18EhrFunctions()},
    activity () { return this.$store.getters['activityStore/activityRecord'] },
    activityName () { return this.activity.learningObjectName },
    consumerId () { return StoreHelper.consumerId() },
    hasActivity () { return this.$store.getters['activityStore/hasActivity'] },
    hasCourse () { return this.$store.getters['courseStore/hasCourse'] },
    course () { return this.$store.getters['courseStore/course']},
    courseTitle () { return this.course ? this.course.title : undefined },
    exitUrl () { return StoreHelper.lmsUrl() },
    visitData () { return this.$store.getters['visit/visitData'] || {}}

  },
  methods: {
    exitToLms () {
      StoreHelper.exitToLms()
    },
    gotoActivity () {
      this.navigate('/ehr')
    },
    gotoCourses () {
      this.navigate('/courses')
    },
    gotoCourse () {
      this.navigate({ name: 'course', query: { courseId: this.course._id } })
    },
    navigate (path) {
      // prevent Vue's NavigationDuplicated
      if (this.$route.path !== path) {
        this.$router.push(path)
      }
    },
    routeClass ( path) {
      return this.$route.path === path ? 'active-link' : ''
    },
    truncate (text) {
      const lim = 25
      return text && text.length > lim ? `${text.substring(0, lim)}...` : text
    }
  }
}

</script>

<style lang="scss" scoped>
@import '../../scss/definitions';
/* 主题色 */
$primary-color: #051F54;
$secondary-color: #6c757d;
$background-color: #ffffff;
$text-color: #333333;
$border-color: #e0e0e0;

/* 导航菜单容器 */
#nav-menu {
  background-color: $background-color;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  max-width: 250px;
  position: relative;

  @media (max-width: 900px) {
    max-width: 100%;
  }
}

/*
.router-item is defined in outside.css. Here we adjust its bottom margin
*/
.router-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  text-decoration: none;
  color: $text-color;
  border-radius: 6px;
  transition: background-color 0.3s, color 0.3s;
  border-left: 3px solid transparent;

  &:hover {
    background-color: #E8F2FD;
    //border-left-color: $primary-color;
  }

  &.active-link {
    background-color: #E8F2FD;
    //border-left-color: $primary-color;
  }

  .fa {
    margin-right: 10px;
    color: $secondary-color;
  }

  span {
    flex: 1;
  }
}
/* 响应式设计 */
@media (max-width: 768px) {
  #nav-menu {
    padding: 10px;
    margin: 10px;
  }

  .router-item {
    //flex-direction: column;
    //align-items: flex-start;
    padding: 10px 0;

    .fa {
      margin-right: 10px;
      margin-bottom: 0px;
    }

    span {
      flex: 1;
    }
  }

  /* 保持响应式缩进 */
  /*.router-item.level2 {
    margin-left: 10px;
  }

  .router-item.level3 {
    margin-left: 20px;
  }*/
}


</style>
