<template lang="pug">
  div(class="EhrNavListItem")
    // add query to open items that are just under headers (e.g. demographics) and open same page after menu click
    ui-link(:name="routeName(path)", :query="{_r: Date.now()}", :class="levelClass")
      div(:class="linkClass", class="linkElement")
        div(class="linkLabel", :title='linkLabel') {{ linkLabelDisplay }}
        div(class="indicator")
          div(:class="dataIndicatorClass")
          div(v-if="level === 1")
            fas-icon(v-show="opened", class="fa top-level", icon="angle-right")
            fas-icon(v-show="!opened", class="fa top-level", icon="angle-down")
            span &nbsp;
      span(class="tooltiptext") {{linkLabel}}
</template>

<script>
import ENList from './EhrNavList'
import UiLink from '../../app/ui/UiLink.vue'
import StoreHelper from '../../helpers/store-helper'
import { t18EhrText, t18ElementLabel } from '@/helpers/ehr-t18'

function truncate (input, lim) {
  return input && input.length > lim ? `${input.substring(0, lim)}...` : input
}
export default {
  name: 'EhrNavListItem',
  components: {
    UiLink,
    ENList
  },
  inject: [ 'pageDataList' ],
  computed: {
    pageInfo () {
      // page data has properties
      // - hasSeed (data),
      // - hasStudent (student data) and
      // - hasMerged (any data)
      // - draftMerged, draftSeed, and draftStudent if page has a draft record
      let k = this.path.pageDataKey
      let d = this.pageDataList()[k]
      if (!d) {
        if (k) console.error('Missing page in pageInfo listing', k, this.path, this.pageDataList())
        d = {}
      }
      return d
    },
    dataIndicatorClass () {
      let colour = ''
      const NEW_INFO = 'circle green-circle'
      const SEED_INFO = 'circle empty-circle'
      const NEW_DRAFT = 'circle draft-circle'
      // const SEED_DRAFT = 'circle empty-draft-circle'
      const NONE = ''
      let isSeedEditing = StoreHelper.isSeedEditing()
      let hd = this.pageInfo
      if (isSeedEditing) {
        colour = hd.draftSeed ? NEW_DRAFT :
          hd.hasSeed ? NEW_INFO : NONE
      } else {
        colour = hd.draftStudent ? NEW_DRAFT :
          hd.hasStudent ? NEW_INFO :
            hd.hasSeed ? SEED_INFO : NONE
      }
      return colour
    },
    levelClass () {
      let lv = this.level || 1
      let lvClass = 'EhrNavListItem__level' + lv
      let isTopAndActive = this.$store.state.visit.topLevelMenu === this.path.name
      let aClass = isTopAndActive ? ' router-link-active' : ''
      let tooltip = ' ' + (this.ehrNavCollapsed ? 'tooltip-aside' : 'tooltip-item')
      return lvClass + aClass + tooltip
    },
    linkLabel () {
      let key = this.path.fqn
      let val = key ? t18ElementLabel({fqn: key}) : undefined
      return val || t18EhrText()[this.path.label] || this.path.label
    },
    linkLabelDisplay () { return this.ehrNavCollapsed ? truncate(this.linkLabel, 5) : this.linkLabel },
    linkClass () {
      let lv = this.level || 1
      return 'EhrNavListItem__link' + lv
    },
    ehrNavCollapsed () { return this.$store.getters['system/ehrNavCollapsed']},
  },
  methods: {
    routeName (path) {
      let route = path.name
      // this is how we let user click on a top level item and get to a page in that item's children
      if (StoreHelper.isEHR_Showing() && path.redirectEhr) {
        route = path.redirectEhr
      } else if (StoreHelper.isLIS_Showing() && path.redirectLis) {
        route = path.redirectLis
      }
      return route
    }
  },
  props: {
    path: { type: Object },
    level: { type: Number },
    opened: { type: Boolean }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/definitions';

$secondary-color: #6c757d;
$success-color: #28a745;
$warning-color: #ffc107;
$danger-color: #dc3545;
$light-color: #f8f9fa;
$dark-color: #343a40;
$text-light-color: #ffffff; // 新增：浅色文字颜色
$text-dark-color: #343a40; // 新增：深色文字颜色

$indicator-size: 12px;
$indicator-spacing: 15px; // 新增：指示器与文字的间距

.linkElement {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  border-radius: 4px;
  //background-color: $dark-color; // 背景颜色改为深色
  //color: $text-light-color; // 文字颜色改为浅色
  width: 100%; // 确保 linkElement 占满整个父容器

}

.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto; // 确保指示器始终位于右侧
  margin-right: 10px;
}

.circle {
  border-radius: 50%;
  height: $indicator-size;
  width: $indicator-size;
  border: 1px solid $secondary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &.green-circle {
    background-color: $success-color;
    border-color: $success-color;
  }

  &.draft-circle {
    background-color: $warning-color;
    border-color: $warning-color;
  }

  &.empty-circle {
    background-color: transparent;
  }
}

.top-level {
  margin-left: 5px;
  color: $text-light-color; // 文字颜色改为浅色
  transition: transform 0.3s ease;

  &.opened {
    transform: rotate(90deg);
  }
}

.linkLabel {
  flex-grow: 1;
  font-size: 1rem;
  font-weight: 500;
  color: $text-light-color; // 文字颜色改为浅色
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: $indicator-spacing; // 确保文字与指示器之间有足够的间距
}

.tooltiptext {
  visibility: hidden;
  background-color: $dark-color;
  color: $text-light-color; // 工具提示文字颜色改为浅色
  text-align: left;
  font-size: 1rem;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  position: absolute;
  z-index: 1;
  bottom: 120%; /* Position above the element */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $dark-color transparent transparent transparent;
  }
}

.linkElement:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Small screens */
@media screen and (max-width: $main-width-threshold1) {
  .linkElement {
    padding: 8px 12px;
  }

  .tooltiptext {
    left: 110%;
    bottom: 50%;
    transform: translateY(50%);
  }

  .tooltiptext::after {
    top: 50%;
    left: -5px;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent $dark-color transparent transparent;
  }
}

.EhrNavListItem {
  &__level1,
  &__level2,
  &__level3 {
    display: flex;
    align-items: center;
  }

  &__level1 {
    font-size: 1.2rem;
  }

  &__level2 {
    padding-left: 20px;
  }

  &__level3 {
    padding-left: 40px; // 增加 level3 的左内边距
  }
  &:hover {
    background-color: darken($dark-color, 5%); // 悬停时背景颜色加深
  }
}

.ehr-branding {
  .router-link-active {
    background-color: darken($dark-color, 5%); 
  }
}

</style>
