<template lang='pug'>
  div
    app-dialog(:isModal="true", ref="theDialog", :useSave="false", @cancel="proceed", cancelButtonLabel="取消")
      h2(slot="header") EMR Sim 语言支持
      div(slot="body")
        p(v-text-to-html="warnMsg")
        p.
          如果您能帮我们翻译成其他语言，请联系
          <a href="https://emrsim.com"> emrsim.com </a>
         

        div
          input(type='checkbox', v-model='noShowPref')
          label  不再显示
</template>

<script>
import AppDialog from '@/app/components/AppDialogShell.vue'
import { APP_LANGUAGE_ES, APP_LANGUAGE_FR } from '@/helpers/store-helper'
import {  t18GetLanguage } from '@/helpers/ehr-t18'
export default {
  components: {
    AppDialog
  },
  data: function () {
    return {
      noShowPref: false
    }
  },
  computed: {
    userPrefNoShowTranslationWarning () { return this.$store.getters['system/userPrefNoShowTranslationWarning']},
    warnMsg () {
      let msg
      msg = ' 注意，某些语言的翻译尚不完整  \n'
      return msg
    }
  },
  methods: {
    showWarningDialog () {
      let requestedLanguage = t18GetLanguage()
      this.noShowPref = this.userPrefNoShowTranslationWarning
      if (!this.noShowPref) {
        if (requestedLanguage === APP_LANGUAGE_FR || requestedLanguage === APP_LANGUAGE_ES ) {
          this.$refs.theDialog.onOpen()
        }
      }
    },
    proceed () {
      this.$refs.theDialog.onClose()
      if (this.noShowPref !== this.userPrefNoShowTranslationWarning) {
        this.$store.dispatch('system/setUserPrefNoShowTranslationWarning', this.noShowPref)
      }
    }
  }
}
</script>
