
const sKeys = {
  API_URL: 'apiUrl',
  SEED_ID: 'seedId',
  LOBJ_ID: 'lobjId',
  VISIT_ID: 'visitId',
  DEMO_TOKEN: 'demoToken',
  DEMO_DATA: 'demoPayload',
  SELECTED_DEMO_PERSONA: 'selectedDemoPersona'
}

export default sKeys
