<template lang="pug">
  div
    ui-confirm(ref="confirmDialog", @confirm="$emit('confirmPageLeave')", @abort="$emit('cancelPageLeave')", set-footer)
</template>

<script>
import UiConfirm from '../../app/ui/UiConfirm'

const HEADER = '您的评估记录未保存'
const TEXT = '点击确认以继续并丢弃未保存数据，点击取消返回'
const CONFIRM = '确认继续且丢弃未保存数据'
const CANCEL = '忽略记录并继续'

export default {
  components: {
    UiConfirm
  },
  methods: {
    showDialog () {
      this.$refs.confirmDialog.showDialog(HEADER, TEXT, CONFIRM, CANCEL)
    }
  },
}
</script>

<style lang="scss" scoped>
</style>