<template lang='pug'>
  div
    div(class="eval-space")
      div(class="input-container")
        ehr-evaluation-input(
          ref="evaluationNoteComponent",
          :disabled='!submitted',
          class="eval-input",
          @hasNewDataChanged='evaluationNotesChanged')
      div(class="right-container")
        div(class="info-container")
          h3 {{student.user.fullName}}
          div {{ currentIndex }} / {{ listLen }}
          div 状态：{{statusText}}
        div(class="controls-container")
          div(class="eval-controls")
            ui-button(value="sec-prev", v-on:buttonClicked="previousStudent()", :disabled="!enablePrev", title='前一名学生')
              fas-icon(icon="angle-left", class='fa')
            ui-button(value="sec-reset", v-on:buttonClicked="resetNotes", :disabled="!canSave", title='重置')
              fas-icon(icon="undo", class='fa')
            ui-button(value="sec-save", v-on:buttonClicked="saveNotes", :disabled="!canSave", title='保存')
              fas-icon(icon="check", class='fa')
            ui-button(value="sec-next", v-on:buttonClicked="nextStudent ()", :disabled="!enableNext", title='后一名学生')
              fas-icon(icon="angle-right", class='fa')
          class-list-actions(
            :studentVisit='student',
            :evaluating='true',
            :hide-eval-ehr='inEhr',
            :hide-eval-raw='!inEhr'
          )

    div(class="flow_across menu_space_across")
      div(class="eval-controls")
        ui-link(:name="'classList'", :query="{activityId: activityId}")
          fas-icon(class="fa", :icon="appIcons.classList")
          span &nbsp; 返回至: 班级列表
      h4 {{ activityName }}
    ui-confirm(class="confirmDialog",
      ref="confirmDialog",
      @confirm="resetNotesConfirmed",
      save-label="确定")
</template>

<script>
import StoreHelper from '@/helpers/store-helper'
import EvalHelper from '@/helpers/eval-helper'
import EhrEvaluationInput from '@/inside/components/EhrEvaluationInput'
import UiButton from '@/app/ui/UiButton'
import ClassListActions from '@/outside/components/lms-activity/ClassListActions'
import { Text } from '@/helpers/ehr-text'
import UiConfirm from '@/app/ui/UiConfirm'
import UiLink from '@/app/ui/UiLink.vue'
import { APP_ICONS } from '@/helpers/app-icons'

export default {
  components: { UiLink, UiConfirm, ClassListActions, UiButton, EhrEvaluationInput },
  data: function () {
    return {
      appIcons: APP_ICONS,
      hasNewData: false,
    }
  },
  props: {
    inEhr: { type: Boolean, default: false },
  },
  computed: {
    temp () {
      let t = this.student
      t = Object.keys(t)
      return t
    },
    activity () {
      return this.$store.getters['activityStore/activityRecord']
    },
    activityId () {
      return this.activity.id
    },
    activityName () {
      return this.activity.learningObjectName
    },
    activityData () {
      return this.student.activityData
    },
    canSave () {
      return this.hasNewData
    },
    classList () { return this.$store.getters['instructor/classList']  },
    currentIndex () {
      let inx = this.student.index
      return isNaN( inx + 1 ) ? 0 : inx + 1
    },
    enablePrev () {
      if (this.hasNewData) return false
      return this.hasPrev
    },
    enableNext () {
      if (this.hasNewData) return false
      return this.hasNext
    },
    hasNext () {
      let index = this.findCurrentIndex()
      index++
      return index < this.classList.length
    },
    hasPrev () {
      let index = this.findCurrentIndex()
      index--
      return index >= 0
    },
    isClosed () {
      return this.activity.closed
    },
    listLen () {
      return this.classList.length
    },
    currentEvaluationStudentId () {
      return this.$store.getters['instructor/currentEvaluationStudentId']
    },
    evaluated () {
      return this.activityData.evaluated
    },
    student () {
      return this.$store.getters['instructor/currentEvaluationStudent']
    },
    studentName () {
      return this.user.fullName
    },
    studentLastActive () {
      return this.student.lastVisitDate
    },
    submitted () {
      return this.activityData.submitted
    },
    statusText () {
      return this.submitted ? Text.SUBMITTED : Text.NOT_SUBMITTED
    },
    showLabels () { return StoreHelper.isOutsideShowButtonLabels() },
    user () {
      return this.student.user
    },
  },
  methods: {
    findCurrent () {
      let list = this.classList
      let id = StoreHelper.getCurrentEvaluationStudentId()
      return list.find(function (elem) {
        return elem._id === id
      }) || {}
    },
    findCurrentIndex () {
      const elem = this.findCurrent()
      return this.classList.findIndex(e => e === elem)
    },
    previousStudent () {
      let index = this.findCurrentIndex()
      index--
      if (index >= 0) {
        let sv = this.classList[index]
        EvalHelper.studentEvaluation(sv._id, this.inEhr)
      }
    },
    nextStudent () {
      let index = this.findCurrentIndex()
      index++
      if (index < this.classList.length) {
        let sv = this.classList[index]
        EvalHelper.studentEvaluation(sv._id, this.inEhr)
      }
    },
    resetNotes () {
      this.$refs.confirmDialog.showDialog('重置评估', '确定要重置评估吗？')
    },
    resetNotesConfirmed () {
      this.$refs.evaluationNoteComponent.resetNotes()
    },
    saveNotes () {
      this.$refs.evaluationNoteComponent.saveNotes()
      // if (this.hasNext) {
      //   this.nextStudent()
      // }
    },
    evaluationNotesChanged (state) {
      this.hasNewData = state
    }
  }

}
</script>

<style scoped lang='scss'>
@use "sass:math";
@import "../../../scss/definitions";

// 颜色方案
$secondary-color: #6c757d;
$background-color: #f8f9fa;
$text-color: #343a40;

// 字体设置
$font-family: 'Roboto', sans-serif;
$font-size-base: 16px;
$line-height-base: 1.5;

// 边距和内边距
$margin-base: 1rem;
$padding-base: 1rem;


.eval-space {
  display: grid;
  grid-template-columns: 2fr 1fr;
  background-color: $background-color;
  padding: 1rem 1rem 1rem 8rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  gap: $margin-base; // 增加列间距
  @media screen and (max-width: 800px) {
    padding: $padding-base;
  }
}

.input-container {
  grid-column: 1 / 2; // 输入框占据左侧2/3
}

.right-container {
  grid-column: 2 / 3; // 文字和按钮部分占据右侧1/3
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-left: 1rem;
}

.info-container {
  margin-bottom: math.div($margin-base, 4);
}

h3 {
  font-family: $font-family;
  font-size: math.div($font-size-base * 1.25, 1); // 使用 math.div
  color: $text-color;
  margin-bottom: math.div($margin-base, 2); // 使用 math.div
}

.eval-input {
  margin-top: $margin-base;
  background-color: $background-color;
}

.controls-container {
  display: flex;
  flex-direction: column;
  gap: math.div($margin-base, 2); // 使用 math.div
  margin-top: auto; // 将按钮部分推到下方
}

.menu_space_across {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $margin-base;
}

h4 {
  font-family: $font-family;
  font-size: math.div($font-size-base * 1.125, 1); // 使用 math.div
  color: $text-color;
  margin: 0;
}

.ui-link {
  text-decoration: none;
  color: #051F54;
  display: flex;
  align-items: center;
  cursor: pointer;

  .fa {
    margin-right: math.div($margin-base, 4); // 使用 math.div
  }
}

.confirmDialog {
  .fa {
    margin-right: math.div($margin-base, 4); // 使用 math.div
  }
}
</style>
